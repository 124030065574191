.vote-interface-title {
  font-size: 2.5rem;
  color: #dcddc3;
  background-clip: text; /* Standard property */
  -webkit-background-clip: text; /* WebKit-based browsers */
  font-weight: 600; /* Set the font weight to 400 */
  text-shadow: 4px 4px 8px rgba(0, 0, 0, 0.5); /* Subtle shadow */
  transition: transform 0.2s ease-in-out; /* Animation for hover */
  text-align: left; /* Centers the heading */
}

.footer-title {
  font-size: 1.5rem;
  color: #dcddc3;
  background-clip: text; /* Standard property */
  -webkit-background-clip: text; /* WebKit-based browsers */
  font-weight: 600; /* Set the font weight to 400 */
}

.propose-title {
  font-size: 2rem;
  color: #dcddc3;
  background-clip: text; /* Standard property */
  -webkit-background-clip: text; /* WebKit-based browsers */
  font-weight: 400; /* Set the font weight to 400 */
  text-shadow: 4px 4px 8px rgba(0, 0, 0, 0.5); /* Subtle shadow */
  transition: transform 0.2s ease-in-out; /* Animation for hover */
  text-align: left; /* Centers the heading */
  margin-bottom: 40px;
  margin-top: 40px;
}

/* Custom separator styling */
.custom-separator {
  border: 0;
  height: 2px;
  background: black;
  border-radius: 5px;
}

.no-proposals-text {
  color: #dcddc3;
}

.proposal-accordions-text {
  color: #dcddc3;
}

@media (max-width: 576px) {
  .btn {
    width: 100%; /* Ensure buttons take the full width on small screens */
  }

  .button-container {
    flex-direction: column; /* Stack buttons vertically on mobile */
    align-items: center;
    width: 100%;
  }

  .equal-width-btn {
    width: 100%; /* Buttons take full width on mobile */
    margin-bottom: 10px; /* Add some space between stacked buttons */
  }

  .mobile-buttons {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }

  .mobile-buttons .btn {
    width: 100%; /* Ensure buttons take the full width on small screens */
    margin-bottom: 10px; /* Space between buttons */
  }

  /* Make tabs stay in a single row on mobile */
  .tabs-container .nav-tabs {
    flex-wrap: nowrap; /* Prevent wrapping */
    overflow-x: auto; /* Make tabs scrollable horizontally */
  }
  
  /* Optional: Make tab items smaller to fit better on mobile */
  .custom-tabs .nav-link {
    padding: 5px 15px; /* Adjust padding for better fit */
    font-size: 14px; /* Smaller font size */
  }

  /* Ensure footer columns take up equal space on mobile */
  footer .col {
    flex: 0 0 100%; /* Ensure columns stack on small screens */
    max-width: 100%;
    text-align: center; /* Center footer text on mobile */
    margin-bottom: 20px; /* Add space between stacked columns */
  }

  /* Ensure footer elements stay on one line on larger screens */
  @media (min-width: 576px) {
    footer .col {
      flex: 0 0 33.3333%; /* Divide into three equal parts */
      max-width: 33.3333%;
      text-align: left;
    }
  }
}

.actions-table {
  border-radius: 8px; /* Round the edges */
  overflow: hidden; /* Ensure the rounded corners are applied to the whole table */
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1); /* Add subtle shadow */
}

.actions-table th,
.actions-table td {
  border-color: #ddd; /* Lighter border color for smoothness */
  padding: 12px; /* Add consistent padding */
}

.actions-table thead {
  border-radius: 8px 8px 0 0; /* Ensure the top row has rounded corners */
}

.actions-table tbody {
  border-radius: 0 0 8px 8px; /* Ensure the bottom row has rounded corners */
}

/* Center the tabs */
/* Center the tabs */
.tabs-container .nav-tabs {
  display: flex;
  justify-content: center;
  border-bottom: none !important; /* Remove the horizontal line */
}

/* Custom Tabs Styling */
.custom-tabs .nav-item {
  margin-right: 20px;
  margin-left: 20px;
}

.custom-tabs .nav-link {
  color: #fff !important;  /* Text color */
  background-color: rgba(33, 33, 55, 0.7);  /* Slightly transparent background */
  border-radius: 5px;  /* Rounded corners */
  padding: 10px 30px;  /* Padding inside the tabs */
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.2);  /* Soft shadow */
  backdrop-filter: blur(10px);  /* Blur effect for the background */
  transition: all 0.3s ease;  /* Smooth transition */
  border: none !important;  /* Remove borders */
}

/* Tab Hover Effect */
.custom-tabs .nav-link:hover {
  background-color: rgba(47, 47, 87, 0.8);  /* Slightly darker on hover */
  box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.3);  /* Stronger shadow on hover */
  transform: translateY(-2px);  /* Lift effect */
}

/* Active Tab */
.custom-tabs .nav-link.active {
  background: linear-gradient(135deg, #23a6d5, #23d5ab);  /* Gradient for active tab */
  color: white !important;  /* Ensure text remains white */
  box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.4);  /* Stronger shadow for active tab */
}

/* Remove default bottom line under the tabs */
.custom-tabs .nav-tabs {
  border-bottom: none !important;
}

.container-fluid {
  min-height: 100vh; /* Ensure the container fills the full viewport height */
  display: flex;
  flex-direction: column;
}

.content {
  flex: 1; /* Take up remaining space between header and footer */
}

.equal-width-btn {
  width: 200px; /* Set fixed width for both buttons */
  display: flex;
  justify-content: center;
  align-items: center;
}