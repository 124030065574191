.fredoka-one {
  font-family: "Fredoka", sans-serif;
  font-optical-sizing: auto;
  font-weight: 500;
  font-style: normal;
  font-variation-settings: "wdth" 100;
}

/* Global styles */
body {
  margin: 0 !important;
  font-family: "Fredoka", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif !important;
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
  background-color: #000811 !important;
  color: #ffffff !important; /* White text */

  background: linear-gradient(-45deg, #7f74aa, #6c60aa, #7d71b3, #7b67b0);
  background-size: 400% 400%;
  animation: gradient 30s ease infinite;
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace !important;
}

/* Scrollable Row */
.scrollable-row {
  display: flex !important;
  overflow-x: auto !important;
  padding-bottom: 2px !important;
  margin-bottom: 2px !important;
}

.scrollable-row::-webkit-scrollbar {
  height: 8px !important;
}

.scrollable-row::-webkit-scrollbar-thumb {
  background-color: #cccccc !important; /* Light gray for scrollbar */
  border-radius: 10px !important;
}

.scrollable-row::-webkit-scrollbar-track {
  background-color: #444444 !important; /* Dark gray track */
}

/* Cards */
.card {
  min-width: 250px !important;
  background-color: rgba(
    21,
    21,
    55,
    0.8
  ) !important; /* Dark, semi-transparent background for a sleek look */
  color: #ffffff !important;
  border: none !important; /* Remove the sharp borders */
  border-radius: 12px !important; /* Rounded corners */
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.2) !important; /* Soft shadow for blurred border effect */
  transition: transform 0.3s ease, box-shadow 0.3s ease !important;
  padding: 15px; /* Add padding inside the card */
}

/* Hover State */
.card:hover {
  transform: translateY(-5px) !important; /* Slight lift effect on hover */
  box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.3) !important; /* Stronger shadow on hover */
}

/* Active State (optional) */
.card:active {
  transform: translateY(0px) !important; /* Reset lift when clicked */
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2) !important; /* Subtle shadow on click */
}

/* Optional: Smoother transition */
.card-transition {
  transition: all 0.3s ease-in-out !important;
}

/* Accordion Titles */

/* Accordion Item */
.accordion-item {
  background-color: rgba(
    26,
    26,
    46,
    0.4
  ) !important; /* Slightly transparent background */
  border: none !important;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2) !important; /* Softer shadow for a blurred effect */
  border-radius: 10px !important; /* Rounded corners for a smoother look */
  margin-bottom: 15px !important; /* Add spacing between accordion items */
  transition: transform 0.3s ease, box-shadow 0.3s ease !important;
}

/* Accordion Item Hover State */
.accordion-item:hover {
  box-shadow: 0px 6px 14px rgba(0, 0, 0, 0.3) !important; /* Stronger shadow on hover for depth */
}

/* Accordion Body */
.accordion-body {
  padding: 20px 15px !important;
  background-color: rgba(
    0,
    0,
    0,
    0.2
  ) !important; /* Slightly transparent black background */
  box-shadow: inset 0px 0px 10px rgba(255, 255, 255, 0.1) !important; /* Soft internal shadow for depth */
  border-radius: 0 0 10px 10px !important; /* Rounded corners at the bottom of the accordion */
}

/* Optional: Shadow on open accordion to enhance depth */
.accordion-body::before {
  content: "";
  position: absolute;
  top: -10px;
  left: 0;
  right: 0;
  height: 10px;
  box-shadow: 0px -4px 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px 10px 0 0;
}

/* Optional: Transition for a smoother opening/closing effect */
.accordion-collapse {
  transition: all 0.3s ease-in-out !important;
}

/* Buttons */
/* Accordion Button */
.accordion-button {
  background-color: rgba(21, 21, 55, 0.5) !important;
  color: #dcddc3 !important;
  border: none !important;
  padding: 10px 20px !important;
  border-radius: 10px !important;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2) !important;
  transition: all 0.3s ease !important;
  font-weight: 600;
}

/* Accordion Button Hover State */
.accordion-button:hover {
  background: linear-gradient(135deg, #0f76c0, #007fee) !important;
  background-size: 200% 200%;
  color: #ffffff !important;
  box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.3) !important;
  transform: translateY(-2px);
}

/* Accordion Button Active State */
.accordion-button:active {
  background: linear-gradient(135deg, #23a6d5, #23d5ab) !important;
  background-size: 200% 200%;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2) !important;
  transform: translateY(0px);
}

/* Focus State */
.accordion-button:focus {
  outline: none !important;
  box-shadow: 0px 0px 12px rgba(0, 255, 255, 0.7) !important;
}

/* Remove Focus/Outline on Collapse */
.accordion-button.collapsed {
  outline: none !important; /* Remove the outline */
  box-shadow: none !important; /* Remove the shadow when collapsed */
}

/* Default accordion arrow color */
.accordion-button::after {
  background-image: url('data:image/svg+xml;charset=utf8,%3Csvg xmlns="http://www.w3.org/2000/svg" fill="white" viewBox="0 0 16 16"%3E%3Cpath fill-rule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"%3E%3C/path%3E%3C/svg%3E') !important;
  opacity: 0.5; /* Slight transparency */
}

/* Links */
a {
  color: #23a6d5 !important; /* Blue links */
}

a:hover {
  color: #23a6d5 !important; /* Darker blue on hover */
}

.accordion {
  margin: 30px auto !important;
}

@media (max-width: 768px) {
  .scrollable-row {
    flex-wrap: nowrap !important;
    overflow-x: auto !important;
  }
}

th {
  font-weight: 400 !important;
}
